import React,{FunctionComponent} from 'react';
import {graphql, PageProps} from 'gatsby';
import ProductLayout from "../../components/layout/ProductLayout/ProductLayout";
import {Grid, Theme, useMediaQuery} from '@mui/material';
import ProductTile from '../../components/product/ProductTile/ProductTile';

type DataType = {
    wpProductCategory: {
        slug: string;
        name: string;
    },
    allWpProduct: {
        nodes: Array<{
            id: string;
            slug: string;
            thumbnail: string;
            title: string;
        }>
    }
};

const ProductCategoryPage: FunctionComponent<PageProps<DataType>> = ({
    data
}) => {
    const category = data.wpProductCategory;
    const products = data.allWpProduct.nodes;
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));


    return <ProductLayout title={`${category.name}`} >
        <Grid container spacing={2}>
            { isMd && products.map(product =>
                <Grid key={product.id} item xs={12} md={6} lg={4}>
                    <ProductTile
                        image={product.thumbnail}
                        name={product.title}
                        url={`/proizvodi/${category.slug}/${product.slug}`}
                    />
                </Grid>
            )}
        </Grid>
    </ProductLayout>
};

export const pageQuery = graphql`
query CategoryDetails($categorySlug: String) {
  wpProductCategory(slug: {eq: $categorySlug}) {
    slug
    name
  },
  allWpProduct(
    filter: {productCategories: {nodes: {elemMatch: {slug: {eq: $categorySlug}}}}}
    sort: {fields: date, order: ASC}
  ) {
    nodes {
      id
      title
      slug
      thumbnail
    }
  }
}
`

export default ProductCategoryPage;
